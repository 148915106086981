import './App.css';
import logo_pidarov from './logo_pidarov.png';

function App() {

  return (
    <div className="App">
      <div>
        <img src={logo_pidarov} />
        <p>
          це ванюче сто знаходиться у <strong>Київі на вулиці Пухівська 3 А</strong>. <br /><br />господар Денис кінчений чорт.<br /><br />
          він буде з вами вкрай люб'язний поки ви не віддасте йому машину на ремонт.<br />
          після цього він вас обдурить, підсуне неоригінальні деталі а грошей візьме як за оригінал.<br /><br />
          ремонт вашого автомобіля зробить <strong>не якісно.</strong> - Обов`язково!!<br /><br />
          якщо буде фарбування то фарба <strong>відвалеться</strong><br /><br />
          не рекомендую звертатися до них... знайдіть щось краще<br />
        </p>
        <br /><br />
        <p>для зв`язку з адміном цього сайту пишіть на <a href="mailto:avalon-gavno@proton.me">avalon-gavno@proton.me</a></p>
      </div>
    </div>
  );
}

export default App;
